.App {
  height: 100vh;
  width: 100vw;

  display: grid;
  grid-template-columns: 2fr 4fr;
  grid-template-rows: 1fr;
  grid-column-gap: 1px;
  grid-row-gap: 0px;
}
.left { 
  grid-area: 1 / 1 / 2 / 2; 
  display: flex;
  z-index: 1000;
  pointer-events: none;
  flex-direction: column;
}
.right { 
  grid-area: 1 / 2 / 2 / 3;
  display: flex;
}
.full { 
  grid-area: 1 / 1 / 2 / 3;
  display: flex;
}

.left > .combine {
  display: flex;
  flex-direction: column;
  background-color: #00CC2D;
  flex: 1;
  align-items: center;
  height: 75%;
  border: 1px #000 solid;
}
.left > .combine:first-of-type {
  border-bottom: none;
}
.left > .combine > div:first-of-type { /*Title*/
  margin-top: 30px;
  font-size: 50px;
  font-weight: 700;
}
.left > .combine > div:last-of-type { /*Data container*/
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 40px;
  font-size: 35px;
}
.left > .combine > div:last-of-type > div {
  margin-top: 5px;
  margin-bottom: 5px;
}
