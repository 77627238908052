.tooltip:global(.leaflet-tooltip) {
  font-size: 30px;
  background: none !important;
  color: #fff;
  border: none;
  box-shadow: none;
  text-shadow: 0px 0px 5px #000;
  font-weight: bold;
}
.tooltip:global(.leaflet-tooltip)::before {
  border: none;
}